import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Refresh from '../../Assets/Refresh.svg';
import { useSelector } from 'react-redux';

const TargetReport = () => {

  const userState = useSelector(state => state.loginData.userData);

  useEffect(() => {
    getZonalTarget(userState.zoneno);
  }, [])

  const [target, setTarget] = useState('');
  const [timeStamp, setTimeStamp] = useState('');
  const [billsDistributedTotal, setBillsDistributedTotal] = useState('');
  const [targetTotal, setTargetTotal] = useState('');
  const [loader, setLoader] = useState(false);

  const getZonalTarget = async (prop) => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/visitreport/getvisitbyzone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zone: prop
      })
    }).then((res) => res.json()).then((response) => {
      setTarget(response.data);
      setTimeStamp(response.timestamp);
      setBillsDistributedTotal(response.billsDistributedTotal);
      setTargetTotal(response.targetTotal);
      setLoader(false);
    }).catch((err) => {
      console.log('err while getting distribution history', err);
      setLoader(false);
    })
  }

  return (
    <div className='targetReport'>
      <Container>
        <Row>
          <div className='d-flex justify-content-center align-items-center mb-2'>
            <Icon.ReceiptCutoff className='me-2' size={22} color='#073c7c' />
            <h5 className='p-0 text-center mb-0'>Target Report</h5>
          </div>
        </Row>

        <Row className='justify-content-center mt-3 mb-2'>
          <Col md={5} className='d-flex justify-content-center align-items-center'>
            {timeStamp ? <div className='d-flex justify-content-center align-items-center'>
              <h6 className='mb-0 me-2'>Last updated:</h6>
              <p className='mb-0'>{timeStamp}</p>
              <img src={Refresh} className='ms-2 mb-2 refresh' onClick={() => getZonalTarget(userState.zoneno)} /> </div> : ""}
          </Col>
        </Row>

        <Row>
          <div className="tbl-container p-0">
            <div className="tbl-fixed p-0" style={{ maxHeight: '75vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: 'maxContent' }}>Zone</th>
                    <th>Gat</th>
                    <th>Bill Distributed</th>
                    <th>Target</th>

                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {target !== null && target !== undefined && target.length > 0
                      ? target.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zone}</td>
                            <td>{row?.gat}</td>
                            <td>{row?.billsDistributed}</td>
                            <td>{row?.target}</td>
                          </tr>
                        );
                      })
                      : "No target data found"}
                  </tbody>

                )}
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'center' }}><strong>{billsDistributedTotal}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{targetTotal}</strong></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </Row>
      </Container>

    </div>
  )
}

export default TargetReport;