import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Table, Spinner, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

const ZoTotalCalling = () => {
    const userState = useSelector(state => state.loginData.userData);

    const [zoneLoader, setZoneLoader] = useState(false);
    const [zoneWiseCalling, setZoneWiseCalling] = useState('');
    const [date, setDate] = useState('');

    const zoneWiseTarget = useRef();
    useEffect(() => {
        getZoneWiseTotalCalling();
    }, [date])

    const getZoneWiseTotalCalling = async () => {
        setZoneLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/tc/zoneWiseReport', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                zonename: userState?.zonename,
                callingDate: date
            })
        }).then((res) => res.json()).then((response) => {
            setZoneWiseCalling(response?.data);
            setZoneLoader(false);
        }).catch((Err) => {
            console.log("Err while getting zone wise calling", Err);
            setZoneLoader(false);
        })
    }

    return (
        <div className='TotalCalling mb-5'>
            <div className="container tbl-container p-0 mt-4" ref={zoneWiseTarget}>
                <div className="row tbl-fixedd p-0">
                    <Table responsive="lg" hover>
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Zone</th>
                                <th>Call Connected</th>
                                <th>Call Not Connected</th>
                                <th>Dail Call Connected</th>
                                <th>Dail Call Not Connected</th>
                                <th>Totals</th>
                            </tr>
                        </thead>

                        {zoneLoader ? (
                            <Spinner variant="primary" className="mt-2" />
                        ) : (
                            <tbody>
                                {zoneWiseCalling !== null && zoneWiseCalling !== undefined && zoneWiseCalling.length > 0
                                    ? zoneWiseCalling.map((row, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.zone}</td>
                                                <td>{row?.callConnect}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.callNotConnect}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.dialCallConnects}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.dialCallNotConnects}</td>
                                                <td>{row?.totalCalls}</td>
                                            </tr>
                                        );
                                    })
                                    : "No calling data found"}
                            </tbody>
                        )}
                    </Table>
                </div>
            </div >
        </div>
    )
}

export default ZoTotalCalling;