import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: 0,
    newDashboard:{ activeKey: 1, subActivekey: 1, activeTab: 'Dashboard' },
    activeMenu:{ activeKey: 0,activeTab: 'old Dashboard' }
};


const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers:{
    setActiveKey(state, action){
      state.activeNumberKey = action.payload
    },
    setActiveMenu(state,action){
      state.activeMenu = { ...state.activeMenu, ...action.payload }
    },
    setNewActiveKey(state, action){
      state.newDashboard = {...state.newDashboard, ...action.payload}
    },
  },
});


export const {setActiveKey,setActiveMenu,setNewActiveKey} = sidebarSlice.actions;
export default sidebarSlice.reducer;