import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Table, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Pagination from "../Common/Pagination";


const SmsSentReport = () => {

    const [data, setData] = useState('');
    const [loader, setLoader] = useState(false);
    const [date, setDate] = useState('');
    const [count, setCount] = useState(null);

    
  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

    useEffect(()=>{
        getSMSSentDetails();
    },[date,pageNo, docPerPage])

    const getSMSSentDetails =async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/tc/getSMSSentDetails',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ callingDate:date,documentsPerPage: docPerPage,
              pageNo: pageNo, })
        }).then((res)=>res.json()).then((response)=>{
            console.log("first", response?.data);
      setNoOfPages(response.noOfPages)
            setData(response?.data);
            setCount(response?.count);
            setLoader(false);
        }).catch((Err)=>{
            console.log("Err while get SMS Sent Details", Err);
            setLoader(false);
        })
    }


  return (
    <div className='SmsSentReport'>
        <Container>
            <Row className='justify-content-end'>
                <Col md={2}>
                    <Form.Label className='mb-0'>Search by date</Form.Label>
                    <Form.Control type="date" value={date} max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => { setDate(moment(e.target.value).format("YYYY-MM-DD")) }}/>
                </Col>
            </Row>
            
        <Row className='mt-3 mb-5'>
            {count&& <h5 className='text-center'>Total- {count}</h5> }
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '72vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th >Property Code</th>
                    <th>Mobile No.</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Property Name</th>
                    <th>Property Address</th>
                    <th>Calling Person</th>
                    <th>Calling Date</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {data !== null && data !== undefined && data?.length > 0
                      ? data.map((row, index) => {
                        return (
                          <tr>
                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                            <td>{row?.propertyCode}</td>
                            <td style={{maxWidth:'120px'}}>{row?.propertyContactNo}</td>
                            <td>{row?.zonename}</td>
                            <td>{row?.gatname}</td>
                            <td style={{ maxWidth: '200px' }}>{row?.propertyName}</td>
                            <td style={{ maxWidth: '200px' }}>{row?.propertyAddress}</td>
                            <td style={{ maxWidth: '250px' }}>{row?.callingPerson_Name + '-' +row?.callingPerson_ContactNo}</td>
                            <td>{moment(row?.callingDate).format('DD-MM-YYYY')}</td>
                          </tr>
                        );
                      })
                      : "No Data Found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>

        </Row>
        <Row>
          {/* <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
            <h6 className="text-center">
              <Icon.BarChart className="me-2" />
              Total - <strong>{data?.count}</strong>
            </h6>
          </Col> */}
          {/* <Col md={8} className="d-flex justify-content-end"> */}
            <Pagination
              currentPage={pageNo}
              totalPages={noOfPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docPerPage}
            />
          {/* </Col> */}
        </Row>          

        </Container>
    </div>
  )
}

export default SmsSentReport;