import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import Menubar from '../Common/Menubar';
import Sidebar from '../Common/Sidebar';
import { useSelector } from 'react-redux';

const CommonLayout = () => {

  const navigate = useNavigate();
  const userState = useSelector(state => state.loginData.userData);

  // if(!userState){
  //   navigate('/dashboard/manage-roles');
  //   console.log("Cant Navigate");
  // }


  return (
    <div>
      <Menubar />
      <Container fluid className=''>
        <Row>
          <div className='sidenav-lg-parent'>
            <Col className='ps-0 d-none d-lg-block'>
              <Sidebar />
            </Col>
            <Col>
              <div className='outletDiv px-3'>
                <Outlet />
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default CommonLayout;