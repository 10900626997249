import React, { useEffect, useState } from 'react';
import { Container, Row, Spinner, Table, Modal, Button, FloatingLabel, Col, Form } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import Add from '../../Assets/AddRounded.svg';
import { useSelector } from 'react-redux';

const ZonalDistribution = () => {

  const [loader, setLoader] = useState(false);
  const [distribution, setDistribution] = useState("");

  const userState = useSelector(state => state.loginData.userData);

  useEffect(() => {
    getZonewiseContractors(userState.zonekey);
  }, [])

  const getZonewiseContractors = async (props) => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/billdistribution/zonewisecontractors', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ zonekey: props })
    }).then((res) => res.json()).then((response) => {
      setDistribution(response.data);
      setLoader(false);
    }).catch((err) => {
      console.log("Error while getting zonewise contractors", err);
      setLoader(false);
    })
  }


  // state for Add user Modal
  const [showAdd, setShowAdd] = useState(false);
  const [row, setRow] = useState({});
  const [bill, setBill] = useState('');
  const [billDate, setBillDate] = useState('');

  // Err Varibales for Add Bill Modal -----------
  const [billErr, setBillErr] = useState(false);
  const [billDateErr, setBillDateErr] = useState(false);

  // Toast Varaibles ------------
  const billAdded = () => toast.success("Bill Added Successfully !");
  const billAddFailed = () => toast.error("Failed to add bill, try again");

  const [btnLoader, setBtnLoader] = useState(false);

  const openAdd = (row) => {
    setRow(row);
    setShowAdd(true);
  }

  const billValidate = () => {
    let validate = true;
    if (bill.length === 0) {
      validate = false;
      setBillErr(true);
    }
    if (billDate.length === 0) {
      validate = false;
      setBillDateErr(true);
    }
    return validate
  }


  const addBill = async () => {

    if (billValidate()) {
      setBtnLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/billdistribution/addbilldistributiontarget', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          visitingPerson_id: row._id,
          visitingPerson_ContactNo: row.contactNo,
          visitingPerson_Name: row.name,
          billDistributionDate: billDate,
          billHandoverCount: bill,
          zone_id: row.zone_id,
          zonename: row.zonename,
          zonekey: row.zonekey,
          zoneno: row.zoneno,
          gat_id: row.gat_id[0],
          gatkey: row.gatkey[0],
          gatname: row.gatname[0],
          createdBy: userState._id
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "Bill added successfully") {
          billAdded();
          setBtnLoader(false);
          setBill("");
          setBillDate("");
          setShowAdd(false);
        }
        else {
          billAddFailed();
        }

      }).catch((err) => {
        setBtnLoader(false);
        setBill("");
        setBillDate("");
        billAddFailed();
        setShowAdd(false);
      })
    }
    else {
      setBtnLoader(false);
    }
  }

  const closeModal = () => {

    // Resetting Modal input fields to emptty ----------

    // Resetting alll error messages -----------
    setBillErr(false);

    setShowAdd(false);

  };

  return (
    <div className='zonalDistribution'>
      <ToastContainer />
      <Container>
        <Row>
          <div className='d-flex justiyf-content-center align-items-center mb-3'>
            <Icon.CashStack className='me-2' size={22} />
            <h5 className='p-0 m-0'>Bill Distribution Report</h5>

          </div>

          <div className="tbl-container p-0">
            <div className="tbl-fixed p-0" style={{ maxHeight: '80vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: 'maxContent' }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Gat</th>
                    <th>Add Bill</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {distribution !== null && distribution !== undefined && distribution.length > 0
                      ? distribution.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                            <td>{row?.contactNo}</td>
                            <td>{row?.gatname}</td>
                            <td><img src={Add} alt='Add Bill' className='add' onClick={() => openAdd(row)} /></td>
                          </tr>
                        );
                      })
                      : "No contractors data found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </Row>
      </Container>


      {/* ----------- Add Modal ---------------- */}
      <Modal dialogClassName="modal" show={showAdd} onHide={closeModal} centered backdrop="static" keyboard={false} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <img src={Add} alt="Add" width={30} />
            <h5 className="mb-0">Assign bill to contractor</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-4">
          <Row className="gy-3 addUser">
            <Col md={4}>
              <FloatingLabel label="Name">
                <Form.Control value={row.name} disabled />
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel label="Bill Handover">
                <Form.Control placeholder='Bill Handover' onChange={(e) => {
                  if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)) {
                    setBill(e.target.value);
                  }
                  e.target.value.length > 0 ? setBillErr(false) : setBillErr(true);
                }}></Form.Control>
                {billErr ? <p> Please enter valid bill amount </p> : null}
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel label="Bill Date">
                <Form.Control type='date' max={new Date().toISOString().split("T")[0]} onChange={(e) => {
                  setBillDate(moment(e.target.value).format("YYYY-MM-DD"));
                  e.target.value.length > 0 ? setBillDateErr(false) : setBillDateErr(true)
                }}></Form.Control>
                {billDateErr ? <p> Please enter valid bill date </p> : null}
              </FloatingLabel>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={closeModal}>
            <Icon.XLg className="me-2" /> Cancel
          </Button>
          {btnLoader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="btn btn-submit" onClick={addBill}>
              <Icon.PlusCircle className="me-2" />
              Add User
            </Button>
          )}
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ZonalDistribution;