import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

const ZonalDistributionHistory = () => {

  const userState = useSelector(state => state.loginData.userData);

  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [mobile, setMobile] = useState("");
  const [distribution, setDistribution] = useState('');
  const [filteredDistribution, setFilteredDistribution] = useState("");
  const [handoverTotal, setHandoverTotal] = useState('');
  const [billsDistributedTotal, setBillsDistributedTotal] = useState('');

  useEffect(() => {
    getZonalDistributionHistory(userState.zonekey);
  }, [])

  const [searchDateErr, setSearchDateErr] = useState(false);
  const [searchMobileErr, setSearchMobileErr] = useState(false);

  const getZonalDistributionHistory = async (prop) => {

    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/billdistribution/getzonewisebilldistributiontarget', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zonekey: prop,
        billDistributionDate: date
      })
    }).then((res) => res.json()).then((response) => {
      setDistribution(response.data);
      setFilteredDistribution(response.data);
      setHandoverTotal(response.handoverTotal);
      setBillsDistributedTotal(response.billsDistributedTotal);
      setLoader(false);
    }).catch((err) => {
      console.log('err while getting distribution history', err);
      setLoader(false);
    })
  }

  // search by date
  const validateDate = () => {
    let validDate = true;
    if (date.length === 0) {
      validDate = false;
      setSearchDateErr(true);
    }
    return validDate;
  }

  const getFilteredDateData = () => {
    if (validateDate()) {
      const result = filteredDistribution !== undefined && filteredDistribution !== null && filteredDistribution.length > 0
        ? filteredDistribution.filter((event) => moment(event?.billDistributionDate).format("YYYY-MM-DD") === date)
        : "";
      setDistribution(result);
    }
  }

  // Search by mobile number
  const validateMobile = () => {
    let validMobile = true;
    if (mobile.length === 0 || mobile.length < 10) {
      validMobile = false;
      setSearchMobileErr(true);
    }
    return validMobile;
  }

  const getFilteredMobileData = () => {
    if (validateMobile()) {
      const result = filteredDistribution !== undefined && filteredDistribution.length > 0
        ? filteredDistribution.filter((event) => event.visitingPerson_ContactNo == mobile)
        : "";
      // console.log("filtered mobile result",result)
      setDistribution(result);
    }
  }


  return (
    <div className='zonalDistributionHistory'>
      <Container>

      <div className='d-flex justify-content-center align-items-center mb-2'>
            <Icon.ReceiptCutoff className='me-2' size={22} color='#073c7c' />
            <h5 className='p-0 text-center mb-0'>Bill Distribution History</h5>
          </div>

        <Row className='justify-content-between mt-4'>
          <Col md={4} className='mb-3 mb-md-0'>
            <div className='d-flex'>
              <Form.Control type='date' max={new Date().toISOString().split("T")[0]} className='me-3'
                onChange={(e) => {
                  setDate(moment(e.target.value).format("YYYY-MM-DD"));
                  e.target.value.length > 0 ? setSearchDateErr(false) : setSearchDateErr(true);
                }} />
              <Button className='btn-submit' onClick={() => getFilteredDateData()}><Icon.Search className='me-2' />Search</Button>
            </div>
            {searchDateErr ? <p>Please select any date</p> : null}
          </Col>


          <Col md={4} >
            <div className='d-flex'>
              <Form.Control className="me-3" name='Mobile' value={mobile} placeholder="Search by mobile number" onChange={(e) => {
                if (
                  !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                  setMobile(e.target.value);
                }
                e.target.value.length > 0 ? setSearchMobileErr(false) : setSearchMobileErr(true);
              }} />
              <Button className='btn-submit' onClick={() => { getFilteredMobileData() }}>
                <Icon.Search className='me-2' />Search</Button>

            </div>
            {searchMobileErr ? <p>Please enter valid mobile no.</p> : null}
          </Col>



        </Row>

       

        <Row className='mt-3'>

          <div className="tbl-container p-0">
            <div className="tbl-fixed p-0" style={{ maxHeight: '80vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: 'maxContent' }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Gat</th>
                    <th>Handover</th>
                    <th>Distributed</th>
                    <th>Assinged Date</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {distribution !== null && distribution !== undefined && distribution.length > 0
                      ? distribution.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.visitingPerson_Name}</td>
                            <td>{row?.visitingPerson_ContactNo}</td>
                            <td>{row?.gatname}</td>
                            <td>{row?.billHandoverCount}</td>
                            <td>{row?.billDistributionCount}</td>
                            <td>{moment(row?.billDistributionDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        );
                      })
                      : "No contractors data found"}
                  </tbody>

                )}
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'center' }}><strong>{handoverTotal}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{billsDistributedTotal}</strong></td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </Row>

      </Container>
    </div>
  )
}

export default ZonalDistributionHistory;