import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const ViewFullScreen = () => {

    const [iframeUrl, setIframeUrl] = useState('');
    const [showIframe, setShowIframe] = useState(false);

    useEffect(() => {
      const storedUrl = sessionStorage.getItem('iframeUrl');
      if (storedUrl) {
        setIframeUrl(storedUrl);
      }
    }, []);

    useEffect(() => {
          // Show loader for 3 seconds, then display the iframe
          const timer = setTimeout(() => {
            setShowIframe(true);
          }, 7000);

          return () => clearTimeout(timer); // Clear the timeout if the component unmounts
      }, []);


  return (
    <div className='ViewFullScreen'>
       {!showIframe && (
        <div className="customLoader">
          <Spinner animation="grow" size="sm" />
          <h6 className="mb-0 mt-2">Loading</h6>
        </div>
      )}
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          style={{ width: '100%', height: '108vh', border: 'none', display: showIframe ? 'block' : 'none' }}
          title="Power BI Report"
        ></iframe>
      )}
    </div>
  )
}

export default ViewFullScreen;