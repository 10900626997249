import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import BarChart from '../Charts/BarChart';
import GraphLoader from "../../Assets/GraphLoader.gif"
import { useSelector } from 'react-redux';


const ZoHome = () => {

  const userState = useSelector(state => state.loginData.userData);
  console.log("userState", userState);
  const [zoneLoader, setzoneLoader] = useState(false);
  const [zoneLabels, setZoneLabels] = useState('');
  const [count, setcount] = useState('');
  const [zoneList, setZoneList] = useState('');
  const [zone, setZone] = useState('');


  useEffect(() => {
    getZonalUpdateData();
  }, [zone])


  // for zonalupdate data graph
  const getZonalUpdateData = async () => {
    const payload = {
      zonekey: userState.zonekey ? userState.zonekey : ""
    };

    console.log("payload", payload);

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/graph/zonalUpdates2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })

      if (response.ok) {
        const responseData = await response.json();
        const chartLabels = responseData.labels || [];
        const chartData = responseData.values || [];

        // Update state variables
        setZoneLabels(chartLabels);
        setcount(chartData);
        setzoneLoader(false);
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (err) {
      console.error("Error while getting zonal update data", err);
    }
  };

  return (
    <div>
      <Container>

        <Row className='welcome d-flex justify-content-center'>
          <h4 className='mb-1'>Welcome, {userState.usertype_keyname[0] === "admin" ? "Admin !" : ""}
            {userState.usertype_keyname[0] === "supervisor" ? "SuperVisor !" : ""}
            {userState.usertype_keyname[0] === "zonalofficer" ? "Zonal Officer !" : ""} </h4>
          <p className='ms-2'>Dashboard of Pimpri Chinchwad Muncipal Corporation Tax Application...</p>
        </Row>


        <div className='d-flex justify-content-center align-items-center mb-2'>
          <Icon.ReceiptCutoff className='me-2' size={22} color='#073c7c' />
          <h5 className='p-0 text-center mb-0'>Zone Wise Chart</h5>
        </div>


        <Row className='mb-3'>
          <Col md={12} className='my-2 d-flex justify-content-center align-items-center'>
            <Card className="ChartCard" style={{ height: "280px", maxHeight: "280px", width: '100%', padding: '20px 10px 12px 0px' }}>
              <h6 className='mb-0 mx-3'>Zone wise Data</h6>
              {
                zoneLoader ?
                  <center><img src={GraphLoader} width="150" maxHeight="250px" /></center> :
                  <BarChart indexAxis='x' chartLabels={zoneLabels} chartLabel={['count']}
                    ColorArray={['rgba(28, 255, 68, 0.43)']}
                    BorderArray={['rgba(0, 225, 39, 1)']}
                    chartData={count} />
              }
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default ZoHome;