import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Table, Form, Button } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';

const ContactRecords = () => {

  const [loader, setLoader] = useState(false);
  const [zoneList, setZoneList] = useState([]);
  const [updateMobilePersonList, setUpdateMobilePersonList] = useState([]);
  const [zone, setZone] = useState('');
  const [updatedById, setUpdatedById] = useState('');
  const [mobile, setMobile] = useState('');
  const [contacts, setContacts] = useState();

  useEffect(() => {
    getZoneList();
    getAllContacts();
  }, [])

  useEffect(() => {
    getupdateMobilePersons();
    setUpdatedById('')
  }, [zone])


  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response?.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  const getupdateMobilePersons = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/updatemobile/getUpdateMobilePerson`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zonekey: zone
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setUpdateMobilePersonList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Update Mobile Persons", err);
      });
  };


  const getAllContacts = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/updatemobile/getUpdateMobile', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        updatedById: updatedById,
        updatedByMobile: mobile,
        zonekey: zone
      })
    }).then((res) => res.json()).then((response) => {
      setContacts(response);
      setLoader(false);
    }).catch((err) => {
      console.log("Err while getting Contact Records", err);
      setLoader(false);
    })
  }

  return (
    <div className='ContactRecords'>
      <Container>
       
        <div className='d-flex justify-content-center align-items-center mb-4'>
          <Icon.Telephone className='me-2' size={22} color='#073c7c' />
          <h5 className='p-0 text-center mb-0'>Update Mobile</h5>
        </div>

        <Row className="justify-content-center mb-4">
          <Col md={5} className="searchCard">
            <div className="label mb-2 mx-auto"><p>Search by zone/persons</p></div>
            <Row className="d-flex justify-content-center parentRow">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={zone} onChange={(e) => setZone(e.target.value)}>
                  <option value="" selected disabled> Select Zone</option>
                  {zoneList !== undefined && zoneList.length > 0 ? (
                    zoneList?.map((row) => {
                      return (
                        <option value={row.zonekey}>{row.zonename + "-" + row.zoneno}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>



              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={updatedById} onChange={(e) => setUpdatedById(e.target.value)} disabled={!zone ? true : false}>
                  <option value="" selected disabled>
                    Select Person
                  </option>
                  {updateMobilePersonList !== undefined && updateMobilePersonList?.length > 0 ? (
                    updateMobilePersonList?.map((row) => {
                      return (
                        <option value={row?.updatedByIds}>{row.updatedByName + '-' + row.updatedByMobiles}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getAllContacts}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>

          </Col>

          <Col md={{ span: 4, offset: 1 }} className='searchCard mt-5 mt-md-0'>
            <div className="label mb-2 mx-auto"><p>Search by update person mobile</p></div>
            <Row className="d-flex justify-content-center">
              <Col md={8} className="mb-3 mb-md-0">
                <Form.Control placeholder='Enter mobile number' value={mobile} onChange={(e) => {
                  if (
                    !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                    e.target.value.length <= 10) {
                    setMobile(e.target.value);
                  }
                }} />
              </Col>
              <Col md={4} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getAllContacts}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>

          </Col>
        </Row>

        <h5 className='text-center'>Total count - {contacts?.updatedMobileCount}</h5>

        <Row>

          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '65vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Property Code</th>
                    <th>Property Name</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Updated Mobile</th>
                    <th>Updated Alt.Mobile</th>
                    <th>Update Person</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {contacts?.data !== null && contacts?.data !== undefined && contacts?.data?.length > 0
                      ? contacts?.data?.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center", cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td>{row?.propertyCode}</td>
                            <td style={{}}>{row?.propertyName}</td>
                            <td>{row?.zonename}</td>
                            <td>{row?.gatname.toString()}</td>
                            <td>{row?.updatedMobile ? row?.updatedMobile : '-'}</td>
                            <td>{row?.updatedAlternateMobile ? row?.updatedAlternateMobile : "-"}</td>
                            <td>{row?.updatedByName ? row?.updatedByName + `${row?.updatedByMobile ? `(` + row?.updatedByMobile + `)` : ''}` : '-'}</td>

                          </tr>
                        );
                      })
                      : "No users data found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>

        </Row>
      </Container>
    </div>
  )
}

export default ContactRecords