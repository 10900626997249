import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  labelMonth: "",
  labelDate:"",
  loding: true,
  // idle, pending, failed
};

const label = createSlice({
  name: 'label',
  initialState,
  reducers: {
    setLabelMonth(state, action) {
     state.labelMonth=action.payload
    },

    setLabelDate(state, action){
        state.labelDate=action.payload
    }
  },
});

export const {setLabelMonth, setLabelDate} = label.actions;
export default label.reducer;