import React, { useState } from "react";
import { Card, Button, Form, Row } from "react-bootstrap";
import PCMC from "../../Assets/PCMC-LOGO.png";
import { ToastContainer, toast } from "react-toastify";

const DeleteUsers = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};

    if (!mobileNumber) {
      errors.mobileNumber = "*Please Enter Mobile Number";
    }

    // if (!email) {
    //   errors.email = "*Please Enter Email";
    // }

    if (!comment) {
      errors.comment = "*Please Enter Comment";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDeleteAccount = () => {
    // Your delete account logic here
    if (validate()) {
      toast.success("Account Deleted Successfully");
      setComment("");
      setEmail("");
      setMobileNumber("");
    }
  };

  return (
    <div className="deleteUsers">
      <ToastContainer />
      <Row className="justify-content-center m-2">
        <h2>Delete User</h2>
      </Row>
      <Card
        style={{ width: "40%", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Card.Body>
          <div className="d-flex justify-content-center mb-4">
            <img src={PCMC} alt="PCMC Survey" width={70} />
          </div>
          <Card.Title>Before Deleting Your Account:</Card.Title>
          <Card.Text>
            <ul>
              <li>Sign out of your devices and web browsers.</li>
              <li>
                When your account is deleted, you will not be able to log in to
                our system or access your account.
              </li>
              <li>Account deletion is permanent.</li>
            </ul>
          </Card.Text>
          <Form>
            <Form.Group className="m-2" controlId="formMobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your mobile number"
                value={mobileNumber}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[0-9]+$/) &&
                    e.target.value.length <= 10
                  ) {
                    setMobileNumber(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setMobileNumber(e.target.value);
                  }
                }}
              />
              {error.mobileNumber ? (
                <div style={{ color: "red" }}>{error.mobileNumber}</div>
              ) : null}
            </Form.Group>
            {/* <Form.Group className="m-2" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error.email ? (
                <div style={{ color: "red" }}>{error.email}</div>
              ) : null}
            </Form.Group> */}
            <Form.Group className="m-2" controlId="formComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              {error.comment ? (
                <div style={{ color: "red" }}>{error.comment}</div>
              ) : null}
            </Form.Group>
          </Form>
          <div className="d-flex justify-content-center">
            <Button
              className="m-2"
              variant="danger"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DeleteUsers;
