import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../Feature/Sidebar/ActiveKeySlice";
import moment from "moment";

const ViewVisitReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.loginData.userData);
  const location = useLocation();
  const visitData = location.state.report;
  const comesFrom = location.state.comesFrom;

  // Delete Modal and state
  const [showDelete, setShowDelete] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [error, setError] = useState({});

  // useEffect(() => {
  //   getVisitReportById();
  // }, []);

  // const getVisitReportById = async () => {
  //   await fetch(
  //     process.env.REACT_APP_BASE_URL + "/billDistribution/getvisitByVisitId",
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         visit_id: VisitId,
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setVisitData(response.data);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  const closeDelete = () => {
    setDeleteReason("");
    setShowDelete(false);
  };

  const validate = () => {
    let errors = {};

    if (!deleteReason) {
      errors.deleteReason = "*Please Enter Delete Reason";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const MavimDeleteBill = async () => {
    if (validate()) {
      await fetch(
        process.env.REACT_APP_BASE_URL + "/billDistribution/mavimDeleteBill",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            visit_id: visitData?.visit_id,
            mavimDeleteId: userState._id, // login user userId
            mavimDeleteName: userState.name, // login user Name
            mavimDeleteContactNo: userState.contactNo, // login user ContactNo
            mavimDeleteReason: deleteReason,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            closeDelete();
            navigate("/dashboard/deleted-bill-history");
            dispatch(
              setActiveKey({
                activeKey: 15,
                subActiveKey: 15.2,
                activeTab: "Deleted Bill History",
              })
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="visitReport">
      <Row className="mb-2 mt-4">
        <h4 className="mb-0 text-center">
          <Icon.FileText className="me-1" /> {comesFrom} Details
        </h4>
      </Row>{" "}
      <Row className="mb-2 mt-4">
        <Col md={2}>
          <Button className="backButton" onClick={() => window.history.back()}>
            <Icon.ArrowLeft size={20} className="me-2" /> Back
          </Button>
        </Col>
      </Row>
      {comesFrom === "Bill Report" ? (
        <Row className="justify-content-end">
          <Col md={2}>
            <Button variant="danger" onClick={() => setShowDelete(true)}>
              Delete Bill
            </Button>
          </Col>
        </Row>
      ) : null}
      {visitData.hasOwnProperty("isWrongProperty") && (
        <h6 className="mt-4 reject-line">
          * नकाराचे कारण :-{" "}
          {visitData.isWrongProperty
            ? "मालमत्ता क्रमांक चुकीचा आहे"
            : "मालमत्ताधारकाचा फॉर्म आधीच भरला आहे."}
        </h6>
      )}
      <Row className="outer-wrapper mx-auto mt-2">
        <div className="table-wrapper">
          <table data-aos="fade-up" data-aos-delay="200">
            <thead>
              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Code
                </th>
                <td>
                  {visitData?.propertyCode ? visitData?.propertyCode : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Owner Name
                </th>
                <td>
                  {visitData?.propertyName ? visitData?.propertyName : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Owner Mobile
                </th>
                <td>
                  {visitData?.mobileUpdated ? visitData?.mobileUpdated : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Address
                </th>
                <td style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                  {visitData?.propertyAddress
                    ? visitData?.propertyAddress
                    : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Category Name
                </th>
                <td>
                  {visitData?.propertyCategoryName
                    ? visitData?.propertyCategoryName
                    : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Image
                </th>
                <td>
                  {visitData?.propertyImg ? (
                    <a href={visitData?.propertyImg} target="_blank">
                      <img
                        src={visitData?.propertyImg}
                        style={{ width: 80, height: 80 }}
                      />
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Gat Name
                </th>
                <td>{visitData?.gatname ? visitData?.gatname : "-"}</td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Zone Name
                </th>
                <td>{visitData?.zonename ? visitData?.zonename : "-"}</td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Visit Person Name
                </th>
                <td>
                  {visitData?.visitingPersonName
                    ? visitData?.visitingPersonName
                    : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Visit Person Mobile
                </th>
                <td>
                  {visitData?.visitingPersonContactNo
                    ? visitData?.visitingPersonContactNo
                    : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Visit Date
                </th>
                <td>
                  {visitData?.visitDate
                    ? moment(visitData?.visitDate).format("DD MMM YYYY")
                    : "-"}
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </Row>
      <Modal
        size="md"
        show={showDelete}
        onHide={() => closeDelete()}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Bill
          </h4>
        </Modal.Header>
        <Modal.Body>
          {/* <h5>Are sure about deleting the Bill ?</h5> */}
          <Form.Group className="m-2" controlId="formComment">
            <Form.Label>Enter Delete Reason:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Delete Reason"
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            />
            {error.deleteReason ? (
              <div style={{ color: "red" }}>{error.deleteReason}</div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner variant="primary" />
          ) : (
            <>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  closeDelete();
                }}
              >
                Cancel
              </Button>

              <Button
                className="btn btn-danger"
                onClick={() => MavimDeleteBill()}
              >
                <Icon.Trash className="me-1" />
                Delete
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewVisitReport;
