import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import GraphLoader from "../../Assets/GraphLoader.gif";
import PartlyDoc from "../../Assets/PartlySubmittedDoc.svg";
import FullyChecked from "../../Assets/FullySubmitChecked.svg";
import Licesne from "../../Assets/License.svg";
import Plus from "../../Assets/Plus.svg";
import EqualTo from "../../Assets/EqualTo.svg";
import Surveys from "../../Assets/Surveys.svg";
import BarChart from "../Charts/BarChart";
import * as Icon from "react-bootstrap-icons";
import moment from "moment/moment";

const NewHome = () => {
  // Count Cards data
  const [countData, setCountData] = useState();
  const [totalOnline,setTotalOnline] = useState()
  const [totalOffline,setTotalOffline] = useState()
  const [loader, setLoader] = useState(false);

  const [zoneData, setZoneData] = useState([]);
  const [zoneWisePropeties, setZoneWiseProperties] = useState([]);
  const [zoneLoader, setZoneLoader] = useState(false);
  const [zoneStartDate, setZoneStartDate] = useState("");
  const [zoneendDate, setZoneEndDate] = useState("");
  const [zonestartDateErr, setZoneStartDateErr] = useState("");
  const [zoneendDateErr, setZoneEndDateErr] = useState("");

  const [contractorData, setContractorData] = useState([]);
  const [contractorWisePropeties, setContractorWiseProperties] = useState([]);
  const [contractorLoader, setContractorLoader] = useState(false);
  const [contractorStartDate, setcontractorStartDate] = useState("");
  const [contractorendDate, setcontractorEndDate] = useState("");
  const [contractorstartDateErr, setcontractorStartDateErr] = useState("");
  const [contractorendDateErr, setcontractorEndDateErr] = useState("");

  // use Effects
  useEffect(() => {
    getDashboardCount();
    getZoneWiseGraph();
    getContractorWiseGraph();
  }, []);

  // get Count API
  const getDashboardCount = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/billDistribution/billDistributionCountDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setTotalOnline(response.propertyNotFoundCount + response.propertyFoundCount)
          setTotalOffline(response.offlinePropertyNotFoundCount + response.offlinePropertyFoundCount)
          setCountData(response);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  // get Zone Graph
  const getZoneWiseGraph = async () => {
    setZoneLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/newGraph/zoneWiseGraph", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fromDate: zoneStartDate,
        toDate: zoneendDate,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setZoneData(response.zones);
          setZoneWiseProperties(response.properties);
          setZoneLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setZoneLoader(false);
      });
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!zoneStartDate) {
      isValid = false;
      setZoneStartDateErr(true);
    } else if (!zoneendDate) {
      isValid = false;
      setZoneEndDateErr(true);
    }
    return isValid;
  };

  const searchByDate = () => {
    if (searchByStartEndValidate()) {
      getZoneWiseGraph();
    }
  };

  // get Zone Graph
  const getContractorWiseGraph = async () => {
    setContractorLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/newGraph/contractorWiseGraph",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fromDate: contractorStartDate,
          toDate: contractorendDate,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractorData(response.names);
          setContractorWiseProperties(response.properties);
          setContractorLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setContractorLoader(false);
      });
  };

  const searchByStartEndValidateContractor = () => {
    let isValid = true;
    if (!contractorStartDate) {
      isValid = false;
      setcontractorStartDateErr(true);
    } else if (!contractorendDate) {
      isValid = false;
      setcontractorEndDateErr(true);
    }
    return isValid;
  };

  const searchByDateContractor = () => {
    if (searchByStartEndValidateContractor()) {
      getContractorWiseGraph();
    }
  };

  

  return (
    <div className="NewHome visitReport">
      <ToastContainer />
      <Container>
        { loader ? <center><Spinner /></center>
        : <>
        {/* Online Counts */}
        <h6 className="headLabel">Online Count</h6>
          <Row className="d-flex justify-content-center">
          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="h-100 w-100">
              <Card.Body>
                <img src={Licesne} alt="Withouy Property Code" />
                <div className="data">
                  <h4>{countData?.propertyFoundCount}</h4>
                  <h6>ऑनलाईन बिल बजावलेली मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={Plus}
              alt="Plus"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="300"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="w-100">
              <Card.Body>
                <img src={PartlyDoc} alt="Fully Checked" />
                <div className="data">
                  <h4>{countData?.propertyNotFoundCount}</h4>
                  <h6>ऑनलाईन बिल न बजावलेली मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={EqualTo}
              alt="EqualTo"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 pe-0 pe-md-5"
          >
            <Card>
              <Card.Body>
                <img src={FullyChecked} alt="Fully Submitted" />
                <div className="data">
                  <h4>{totalOnline}</h4>
                  <h6>एकूण ऑनलाईन बिल सबमिट मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Offline Counts */}
        <h6 className="headLabel">Offline Count</h6>
        <Row className="d-flex justify-content-center">
          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="h-100 w-100">
              <Card.Body>
                <img src={Licesne} alt="Withouy Property Code" />
                <div className="data">
                  <h4>{countData?.offlinePropertyFoundCount}</h4>
                  <h6>ऑफलाईन बिल बजावलेली मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={Plus}
              alt="Plus"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="300"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="w-100">
              <Card.Body>
                <img src={PartlyDoc} alt="Fully Checked" />
                <div className="data">
                  <h4>{countData?.offlinePropertyNotFoundCount}</h4>
                  <h6>ऑफलाईन बिल न बजावलेली मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={EqualTo}
              alt="EqualTo"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 pe-0 pe-md-5"
          >
            <Card>
              <Card.Body>
                <img src={FullyChecked} alt="Fully Submitted" />
                <div className="data">
                  <h4>{totalOffline}</h4>
                  <h6>एकूण ऑफलाईन बिल सबमिट मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Total Counts */}
        <h6 className="headLabel">Total Count</h6>
        <Row className="d-flex justify-content-center">
          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="h-100 w-100">
              <Card.Body>
                <img src={Licesne} alt="Withouy Property Code" />
                <div className="data">
                  <h4>{totalOnline}</h4>
                  <h6>एकूण ऑनलाईन बिल सबमिट मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={Plus}
              alt="Plus"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="300"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="w-100">
              <Card.Body>
                <img src={PartlyDoc} alt="Fully Checked" />
                <div className="data">
                  <h4>{totalOffline}</h4>
                  <h6>एकूण ऑफलाईन बिल सबमिट मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
            <img
              src={EqualTo}
              alt="EqualTo"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            />
          </Col>

          <Col
            md={6}
            lg={4}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 pe-0 pe-md-5"
          >
            <Card>
              <Card.Body>
                <img src={FullyChecked} alt="Fully Submitted" />
                <div className="data">
                  <h4>{ totalOnline + totalOffline}</h4>
                  <h6>एकूण बिल सबमिट मालमत्ता</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        </> }

        <h6 className="headLabel">Other Counts</h6>
        { loader ? <center><Spinner/></center>
        : <Row className="d-flex justify-content-center">
        <Col
          md={3}
          data-aos="fade-up"
          data-aos-delay="400"
          className="mb-4 pe-0 pe-md-4"
        >
          <Card>
            <Card.Body className="d-flex flex-column New-card">
              <h4>{countData?.rejectedCount}</h4>
              <span className="new-card-label">Rejected Bills</span>
            </Card.Body>
          </Card>
        </Col>

        <Col
          md={3}
          data-aos="fade-up"
          data-aos-delay="400"
          className="mb-4 pe-0 pe-md-4"
        >
          <Card>
            <Card.Body className="d-flex flex-column New-card">
              <h4>{countData?.mobileUpdateCount}</h4>
              <span className="new-card-label">Mobile Number verified</span>
            </Card.Body>
          </Card>
        </Col>

        <Col
          md={3}
          data-aos="fade-up"
          data-aos-delay="400"
          className="mb-4 pe-0 pe-md-4"
        >
          <Card>
            <Card.Body className="d-flex flex-column New-card">
              <h4>{countData?.altMobileCount}</h4>
              <span className="new-card-label">Alternate Mobile Number</span>
            </Card.Body>
          </Card>
        </Col>

        <Col
          md={3}
          data-aos="fade-up"
          data-aos-delay="400"
          className="mb-4 pe-0 pe-md-4"
        >
          <Card>
            <Card.Body className="d-flex flex-column New-card">
              <h4>{countData?.upicIdCount}</h4>
              <span className="new-card-label">UPIC ID taken</span>
            </Card.Body>
          </Card>
        </Col>
      </Row> }

        <h6 className="headLabel">Zone Wise Properties Graph</h6>
        <Row className="justify-content-center mb-5">
          <Col md={12}>
            <Row className="justify-content-center">
              <Col md={6} className="searchCard justify-content-center mb-4">
                <div className="label mb-2 mx-auto">
                  <p>Search by Start Date/End Date</p>
                </div>
                <Row className="d-flex justify-content-center">
                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={zoneStartDate}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setZoneStartDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        setZoneEndDate("");
                        e.target.value.length > 0 && setZoneStartDateErr(false);
                      }}
                    />
                    {zonestartDateErr ? (
                      <p className="errMsg">Please select Start Date</p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={zoneendDate}
                      min={zoneStartDate ? zoneStartDate : ""}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setZoneEndDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        e.target.value.length > 0 && setZoneEndDateErr(false);
                      }}
                    />
                    {zoneendDateErr ? (
                      <p className="errMsg">Please select End Date</p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={2} className="mt-4">
                    <Button className="btn btn-submit" onClick={searchByDate}>
                      <Icon.Search className="me-2" size={14} />
                      Search
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Card
              className="ChartCard"
              style={{
                height: "280px",
                maxHeight: "280px",
                width: "100%",
                padding: "20px 10px 12px 0px",
                overflowX: "auto",
              }}
            >
              <h6 className="mb-0 mx-3">Zone wise Data</h6>
              {zoneLoader || loader ? (
                <center>
                  <img src={GraphLoader} width="150" maxHeight="250px" />
                </center>
              ) : (
                <div className="mobile-res">
                  <BarChart
                    indexAxis="x"
                    chartLabels={zoneData}
                    chartLabel={["Count of Properties"]}
                    ColorArray={["rgb(238, 155, 119)"]}
                    BorderArray={["rgb(227, 88, 28)"]}
                    chartData={zoneWisePropeties}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <h6 className="headLabel">Contractor Wise Properties Graph</h6>
        <Row className="justify-content-center mb-5">
          <Col md={12}>
            <Row className="justify-content-center">
              <Col md={6} className="searchCard justify-content-center mb-4">
                <div className="label mb-2 mx-auto">
                  <p>Search by Start Date/End Date</p>
                </div>
                <Row className="d-flex justify-content-center">
                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={contractorStartDate}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setcontractorStartDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        setcontractorEndDate("");
                        e.target.value.length > 0 &&
                          setcontractorStartDateErr(false);
                      }}
                    />
                    {contractorstartDateErr ? (
                      <p className="errMsg">Please select Start Date</p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={contractorendDate}
                      min={contractorStartDate ? contractorStartDate : ""}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setcontractorEndDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        e.target.value.length > 0 &&
                          setcontractorEndDateErr(false);
                      }}
                    />
                    {contractorendDateErr ? (
                      <p className="errMsg">Please select End Date</p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={2} className="mt-4">
                    <Button
                      className="btn btn-submit"
                      onClick={searchByDateContractor}
                    >
                      <Icon.Search className="me-2" size={14} />
                      Search
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Card
              className="ChartCard"
              style={{
                height: "280px",
                maxHeight: "280px",
                width: "100%",
                padding: "20px 10px 12px 0px",
                overflowX: "auto",
              }}
            >
              <h6 className="mb-0 mx-3">Contractor wise Data</h6>
              {contractorLoader || loader ? (
                <center>
                  <img src={GraphLoader} width="150" maxHeight="250px" />
                </center>
              ) : (
                <div className="mobile-res">
                  <BarChart
                    indexAxis="x"
                    chartLabels={contractorData}
                    chartLabel={["Count of Properties"]}
                    ColorArray={["rgb(238, 155, 119)"]}
                    BorderArray={["rgb(227, 88, 28)"]}
                    chartData={contractorWisePropeties}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewHome;
