import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FloatingLabel, Button, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

const ZonalContractorReport = () => {

  const userState = useSelector(state => state.loginData.userData);

  const [gatList, setGatList] = useState([]);
  const [gat, setGat] = useState("");
  const [gatErr, setGatErr] = useState(false);


  const [loader, setLoader] = useState(false);

  const [contractor, setContractor] = useState([]);


  useEffect(() => {
    getGatData();
  }, []);

  const getGatData = async () => {

    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ zonekey: userState.zonekey })
    }).then((res) => res.json()).then((response) => {
      setGatList(response.data);
    })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });

    getDefaultContractors(userState.zonekey);
  }

  // ------------ Getting Contractors onChange gat ---------------


  //  Getting Contrators with deafult Zone --------------
  const getDefaultContractors = async (props) => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/user/getcontractors", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ zonekey: props, gatkey: "" }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "No Contractor Found") {
          setLoader(false);
          setContractor(response);
          console.log("No Contractor Found==>", response.data);
        } else {
          setContractor(response);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error while Fetching Visitor Zone/Gat", err);
      });
  }



  const GatValidate = () => {
    let gatValid = true;
    if (gat.length === 0) {
      gatValid = false;
      setGatErr(true);
    }
    return gatValid;
  }

  const getContractors = async () => {
    if (GatValidate()) {
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/user/getcontractors", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ zonekey: userState.zonekey, gatkey: gat }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "No Contractor Found") {
            setLoader(false);
            setContractor(response);
          } else {
            setContractor(response);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log("Error while Fetching Visitor Zone/Gat", err);
        });
    }
    else {
      setLoader(false);
    }


  }

  return (
    <div className='zonalContractor'>
      <Container>
        <Row className='justify-content-center'>
          <Col md={7}>
            <Row>
              <Col md={4}>
                <FloatingLabel label="Zone">
                  <Form.Control value={userState.zonename + "-" + userState.zoneno} />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel label="Gat">
                  <Form.Select onChange={(e) => { setGat(e.target.value); e.target.value > 0 ? setGatErr(false) : setGatErr(true) }}>
                    <option selected disabled>Select any gat</option>
                    {gatList !== null && gatList !== undefined && gatList.length > 0 ? gatList.map((row) => {
                      return (
                        <option value={row.gatkey}>{row.mar_gatname + "-" + row.gatname}</option>
                      )
                    }) : "No Gat List Found"
                    }
                  </Form.Select>
                  {gatErr ? <p>Please select any gat</p> : ""}
                </FloatingLabel>

              </Col>
              <Col md={4} className='justify-content-center d-flex align-items-center text-center'>
                <Button className='btn btn-submit' onClick={getContractors}><Icon.Search className='me-2' />Search</Button>
              </Col>
            </Row>
          </Col>

        </Row>

        {/* ---------------------- Starting Table Here ------------------------- */}
        <Row className="visitors mt-4">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '75vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: 'maxContent' }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Visit Count<br></br>(Found)</th>
                    <th> Visit Count<br></br>(Not Found)</th>
                    <th>Total Visits</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {console.log("at table=====>", contractor)}
                    {contractor.data !== null && contractor.data !== undefined && contractor.data.length > 0
                      ? contractor.data.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                            <td>{row?.contactNo}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zonename}</td>
                            <td>{row?.gatname.toString()}</td>
                            <td>{row?.visitCountPF}</td>
                            <td>{row?.visitCountPNF}</td>
                            <td>{row?.totalCount}</td>
                          </tr>
                        );
                      })
                      : "No contractors data found"}
                  </tbody>
                )}

                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.totalVisitPF}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.totalVisitPNF}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.count}</strong></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </Row>


      </Container>
    </div>
  )
}

export default ZonalContractorReport;