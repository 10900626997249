import React, { useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button, Card, Accordion, Spinner} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import { useNavigate } from 'react-router-dom';
import CallerNav from './CallerNav';
import Calling from '../../Assets/Calling.svg';
import Lottie from 'react-lottie';
import PropertyLottie from '../../Assets/BuildingLoader2.json';
import { ToastContainer, toast } from 'react-toastify';
import UserCardSkeleton from './UserCardSkeleton';

const PropertyCalling = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

    const userState = useSelector(state => state.loginData?.userData);
    const [gatList, setGatList] = useState([]);
    const [gat, setGat] = useState('');
    const [loader, setLoader] = useState(false);
    const [propertyTaxList, setPropertyTaxList] = useState([]);


    const [userLoader, setUserLoader] = useState(false);
    const [propertyUsersList, setPropertyUserList] = useState([]);
    const [relation, setRelation] = useState('');

    // States for Pagination ----------
      const [pageNo, setPageNo] = useState(1);
      const [docPerPage, setDocPerPage] = useState(20);
      const [noOfPages, setNoOfPages] = useState();

    useEffect(()=>{
        getGatList();
    },[])

    useEffect(()=>{
      if(gat){
        getPropertyTaxList();
      }
    },[gat, docPerPage, pageNo])

    // function for pagination    
      const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
     };
  
    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    const getGatList = async()=>{
        try{
            const response = await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ zonekey: userState?.zonekey })
              })
              
              const data = await response.json();
              setGatList(data?.data);
              console.log("data gat", data?.data);

        } catch(Err){
            console.log("Err while getting gatList at Property Calling", Err);
        }
    }

    const getPropertyTaxList = async()=>{
        setLoader(true);
        try{
          const response = await fetch(process.env.REACT_APP_BASE_URL + '/propertyListNew/propertyTaxList', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ zone: userState?.eng_zonename, gat:gat, documentsPerPage:docPerPage, page:pageNo })
          })
        
          const data = await response.json();
          setPropertyTaxList(data?.data);
          setNoOfPages(data?.noOfPages);
          console.log("Respomse", data?.data);
          setLoader(false);
        }catch(Err){
          console.log("Err while getting property List", Err);
          setLoader(false);
        }

    }

    const getPropertyUserList = async(propertycode)=>{
      setUserLoader(true);
      try{
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/propertyListNew/releventData',{
          method:'POST',
          headers:{'Content-Type':'application/json'},
          body:JSON.stringify({propertycode:propertycode}) 
        })
        const data = await response.json();
        setPropertyUserList(data?.data);
        setUserLoader(false);
      }catch(Err){
        console.log("Err while getting Property User List", Err);
        setUserLoader(false);
      }
    }

    /*-------- trying ------- */
    const [callingStatusList, setCallingStatusList] = useState([]);
    const [invalidIndices, setInvalidIndices] = useState([]);
  
    useEffect(() => {
      const defaultStatusList = propertyUsersList.map(user => ({
        isRelevant: null,
        relation: '',
        callingPropertyCode: user?.propertycode,
        callingPropertyNumber: user?.own_mobile
      }));
      setCallingStatusList(defaultStatusList);
    }, [propertyUsersList]);
  
    const handleRelevanceChange = (userIndex, isRelevant) => {
      const updatedStatusList = [...callingStatusList];
      updatedStatusList[userIndex] = {
        ...updatedStatusList[userIndex],
        isRelevant: isRelevant === 'Yes',
      };
      setCallingStatusList(updatedStatusList);
    };
  
    const handleRelationChange = (userIndex, selectedRelation) => {
      const updatedStatusList = [...callingStatusList];
      updatedStatusList[userIndex] = {
        ...updatedStatusList[userIndex],
        relation: selectedRelation
      };
      setCallingStatusList(updatedStatusList);
    };
  
    const handleSave = async(propertyCode, propertyIndex, userId) => {
      // Validate if "Yes" is selected, relation must be selected
      const newInvalidIndices = callingStatusList.reduce((acc, item, index) => {
        if (item.isRelevant && !item.relation) {
          acc.push(index);
        }
        return acc;
      }, []);
  
      setInvalidIndices(newInvalidIndices);
  
      // Scroll to the first invalid card
      if (newInvalidIndices.length > 0) {
        const firstInvalidIndex = newInvalidIndices[0];
        const cardElement = document.getElementById(`userCard-${firstInvalidIndex}`);
        if (cardElement) {
          setTimeout(() => {
            cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 100); // Delay added to ensure DOM updates before scrolling
        }
        return;
      }
  
      // Filter out only selected items
      const selectedStatusList = callingStatusList.filter(item => item.isRelevant !== null);
      // Call your API with the selectedStatusList array
      console.log("final List",selectedStatusList);

      if(selectedStatusList?.length === 0){
        toast.error('Please select record first');
      }
      else{
        const payload = {
          propertycode: propertyCode,
          callingStatus: selectedStatusList?.map(status => ({
            callingPropertyCode: status.callingPropertyCode,
            callingPorpertyNumber: status.callingPropertyNumber,
            isRelevent: status.isRelevant,
            propertyName: status.propertyName, // Assuming you have this property available
            relation: status.relation,
            isCall: true // Assuming this should always be true
          })),
          callingTime: new Date().toISOString(), // Current date and time
          callBy_id: userState?._id,
          callByNumber: userState?.contactNo,
          callByName: userState?.name
        };
        try{
          const response = await fetch(process.env.REACT_APP_BASE_URL + '/propertyListNew/reportSubmitReleventNR',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(payload)
          })
          const data = await response.json();
          if(data.message === "Updated Successfully"){
            toast.success("Updated Successfully !");
            setUserLoader(false);
            // Assuming your response data contains the updated property information
              const updatedPropertyList = [...propertyTaxList];
              updatedPropertyList[propertyIndex] = data.updatedProperty;
              setPropertyTaxList(updatedPropertyList);
            // getPropertyTaxList();
          }
          
        }catch(Err){
          console.log("Err while getting Property User List", Err);
          setUserLoader(false);
        }
      }
    };
  

  const PropertyLoader={
    loop: true,
    autoplay: true,
    animationData: PropertyLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }


  return (
    <div className='PropertyCalling'>
      <ToastContainer/>
      <CallerNav/>
        <Container className='pt-5'>
            <Row>
                <h2 className='text-center mt-4' style={{fontWeight:'600'}}>PCMC Property Calling</h2>
            </Row>
            
            <Row className="d-flex justify-content-center justify-content-md-end mt-2">
              <Col xs={8} md={2} className="mb-3 mb-md-0">
                <Form.Control as="select" disabled>
                  {userState.zonekey && (
                    <option key={userState.zonekey} value={userState.zonekey}>
                      {userState.zonename + "-" + userState.zoneno}
                    </option>
                  )}
                </Form.Control>
              </Col>

              <Col xs={8} md={2} className="mb-3 mb-md-0">
                <Form.Select value={gat} onChange={(e) => setGat(e.target.value)}>
                  <option value="" selected disabled>Select Gat</option>
                  {gatList !== undefined && gatList.length > 0 ? (
                    gatList?.map((row) => {
                      return (
                        <option value={row?.gatname}>{row.mar_gatname + '-' + row.gatname}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              {/* <Col md={1} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" >
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col> */}
            </Row>

            { !gat ? (
                <div className='text-center mt-4' data-aos="zoom-in">
                    <img src={Calling} width={250} className='mx-auto' alt="Property Calling"/>
                    <h3 className="text-center mt-4" style={{fontWeight:'600'}}>Welcome !</h3>
                    <h5 className='px-3 px-md-0'>Please select a gat to get property tax list.</h5>
                </div>
             
              ) : loader  ? ( 
                  <center>
                    <Lottie options={PropertyLoader} className='mt-3' width={400} />
                  </center>
                ) : (
                  <Accordion className='mt-3'>
                  <Row>
                  {
                    propertyTaxList!==undefined && propertyTaxList!==null && propertyTaxList.length !== 0 ?
                    propertyTaxList?.map((row, propertyIndex)=>{
                      return(
                        <Col md={4} className='mb-4'>
                          <Accordion.Item eventKey={propertyIndex}>
                          <Accordion.Header onClick={()=>getPropertyUserList(row?.propertycode)}>
                          <Card className='PropertyCard h-100'>
                            <div><Icon.Code className='me-2'/>{row?.propertycode}</div>
                            <div><Icon.Person className='me-2'/>{row?.propertyname}</div>
                            <div><Icon.GeoAlt className='me-2'/>{row?.useType}</div>
                          </Card>
                          </Accordion.Header>
                          
                            <Accordion.Body style={{background:'#b5d6ff'}}>
                            {
                              userLoader ? <UserCardSkeleton/> :
                            
                              <div>
                                {
                                  propertyUsersList!==undefined && propertyUsersList!==null && propertyUsersList?.length>0 ?
                                    propertyUsersList?.map((user, userIndex)=>{
                                      return(
                                        <Card key={userIndex} id={`userCard-${userIndex}`} className='userCard' >
                                          <a className='PerWatchBtn' 
                                              style={{ background: user?.percentMatch <= 35 ? 'red' 
                                                        : user?.percentMatch <= 75 ? 'orange' : 'green'
                                            }}>{user?.percentMatch} %
                                          </a>
                                          {user?.isDefaulter === 1 && <p className='defaulter'>Defaulter</p>}
                                          <p><Icon.Code className='me-2'/>{user?.propertycode}</p>
                                          {user?.own_mobile && <p><Icon.Telephone className='me-2'/>{user?.own_mobile}</p>}
                                          <p><Icon.Person className='me-2'/>{user?.consumer_name}</p>
                                          <p><Icon.GeoAlt className='me-2'/>{user?.address}</p>
                                          {user?.isCall ===true &&<p style={{color:'green', fontWeight:'600'}}>
                                            <Icon.CheckCircle className='me-2'/>Calling Done</p> }
                                          <Form.Group key={`radio-${userIndex}`} onChange={(e) => handleRelevanceChange(userIndex, e.target.value)}>
                                            <Form.Label className="Label-muted">Relevance</Form.Label>
                                            <Form.Check label='Yes' name={`group-${userIndex}`} type="radio" id={`Yes-${userIndex}`} value="Yes" inline />
                                            <Form.Check label="No" name={`group-${userIndex}`} type="radio" id={`No-${userIndex}`} value="No" inline />
                                          </Form.Group>

                                    {callingStatusList[userIndex]?.isRelevant && (
                                      <div>
                                        <Form.Group key={`select-${userIndex}`}>
                                          <Form.Label className="Label-muted">Relation</Form.Label>
                                          <Form.Select value={callingStatusList[userIndex]?.relation || ''} onChange={(e) => handleRelationChange(userIndex, e.target.value)}>
                                            <option value="">Select relation</option>
                                            <option value="brother">Brother</option>
                                            <option value="father">Father</option>
                                            <option value="mother">Mother</option>
                                            <option value="neighbor">Neighbor</option>
                                            <option value="other">Other</option>
                                          </Form.Select>
                                        </Form.Group>

              {
                invalidIndices.includes(userIndex) && !callingStatusList[userIndex]?.relation && 
                  <p className="errMsg">Please select a relation for 'Yes'</p>
              }
            </div>
          )}
                          <a href={`tel:${user?.own_mobile}`} className='callBtn mt-3'>
                              <Icon.Telephone className='me-2'/>Call</a>
                                        </Card>
                                      )
                                    }) : <p>No User Found</p>
                                }

                                <Button className='btn-submit mx-auto' 
                                  onClick={()=>handleSave(row?.propertycode, propertyIndex, row._id)}>
                                  <Icon.Send className='me-2'/>Save
                                </Button>
                              </div>
                            }
                            </Accordion.Body>
                    
                          </Accordion.Item>
  
                        
                        </Col>
                        
                      )
                    })
                    : <p className='text-center'>No Data Found</p>
                  }
                  </Row>
                  </Accordion>
            )
          }


      {
        !gat || gat?.length===0 &&
             <Row>
                        <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                            <h6 className='text-start mb-0'>
                                <Icon.People className='me-2'/>Total - <strong>Dummy</strong>
                            </h6>
                        </Col>
                        <Col md={8} className='d-flex justify-content-end p-md-0'>
                            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                            onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
                        </Col>
             </Row>
      }

       
            
          
        </Container>
    </div>
  )
}

export default PropertyCalling;