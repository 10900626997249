import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Tab,
  Nav,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";

const RejectedFormList = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const navigate = useNavigate();

  // Variables for Rejected Data ------------
  const [rejectedList, setRejectedList] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [loader, setLoader] = useState(false);

  // Search by Mobile----
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobErr, setSearchMobErr] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  //   useEffect API Calls
  useEffect(() => {
    getRejectedListData();
  }, [docPerPage, pageNo]);

  // get List API
  const getRejectedListData = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/billDistribution/getRejectedDataPGN",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          visitingPersonContactNo: searchMobile,
          fromDate: startDate,
          toDate: endDate,
          documentsPerPage: docPerPage,
          page: pageNo,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setRejectedList(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }else{
          setRejectedList()
          setNoOfPages(0)
          setLoader(false);

        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    } else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  };

  const searchByDate = () => {
    if (searchByStartEndValidate()) {
      getRejectedListData();
    }
  };

  const searchByMobile = () => {
    if (searchMobile.length === 10) {
      getRejectedListData();
    } else {
      toast.error("Please Enter Valid Mobile Number.");
    }
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="searchCard align-items-end m-2">
            <div className="label mb-2 mx-auto">
              <p>Search by mobile</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Control
                className="me-3 mt-3"
                placeholder="Enter mobile number"
                value={searchMobile}
                name="contactNo"
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    setSearchMobile(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setSearchMobErr(false)
                    : setSearchMobErr(true);
                }}
              />

              <Button className="btn-submit mt-3" onClick={searchByMobile}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {searchMobErr ? <p>Please enter valid mobile number</p> : null}
          </Col>
          <Col md={6} className="searchCard justify-content-center m-2">
            <div className="label mb-2 mx-auto">
              <p>Search by Start Date/End Date</p>
            </div>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setEndDate("");
                    e.target.value.length > 0 && setStartDateErr(false);
                  }}
                />
                {startDateErr ? (
                  <p className="errMsg">Please select Start Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  min={startDate ? startDate : ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                    e.target.value.length > 0 && setEndDateErr(false);
                  }}
                />
                {endDateErr ? (
                  <p className="errMsg">Please select End Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={2} className="mt-4">
                <Button className="btn btn-submit" onClick={searchByDate}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table responsive="lg" hover>
                <thead>
                <th>Sr No.</th>
                  <th>Propety Code</th>
                  <th>Visit Person Name</th>
                  <th>Visit Person Contact No.</th>
                  <th>Visit Date</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {loader ? (
                    <center>
                      <Spinner />
                    </center>
                  ) : (
                    rejectedList &&
                    rejectedList.data.length > 0 ?
                    rejectedList.data.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>{row?.propertyCode ? row?.propertyCode : "-"}</td>
                          <td>
                            {row?.visitingPersonName
                              ? row?.visitingPersonName
                              : "-"}
                          </td>
                          <td>
                            {row?.visitingPersonContactNo
                              ? row?.visitingPersonContactNo
                              : "-"}
                          </td>
                          <td>
                            {row?.visitDate
                              ? moment(row?.visitDate).format("DD MMM YYYY")
                              : "-"}
                          </td>
                          <td className="text-center">
                            <Icon.Eye
                              style={{ cursor: "pointer" }}
                              size={25}
                              onClick={() =>
                                navigate("/dashboard/view-visit-report", {
                                  state: {
                                    report: row,
                                    comesFrom: "Visit Report",
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    }) : "No data found"
                  )}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total - <strong>{rejectedList?.count}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default RejectedFormList;
