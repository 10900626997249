import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";
import moment from "moment";

const NewGatWiseBillAnalysis = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [zoneList, setZoneList] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);

  //   useEffect API Calls
  useEffect(() => {
    getZoneList();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      getGatWiseListData();
    }
  }, [selectedZone]);

  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/zoneData/getZoneData", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
        setSelectedZone(response.data[0].zonename)
      })
      .catch((err) => {
        console.log("err while gettting zone=>", err);
      });
  };

  const getGatWiseListData = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/billDistribution/getZonewiseGatCount",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          zonename: selectedZone,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setData(response);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row>
          <Row className="justify-content-center mb-3">
            <Col md={4} className="searchCard">
              <div className="label mb-2 mx-auto">
                <p>Search by zone</p>
              </div>
              <div className="d-flex mt-1">
                <Form.Select value={selectedZone} onChange={(e) => setSelectedZone(e.target.value)}>
                  <option value="" selected disabled>
                    Select zone
                  </option>
                  {zoneList !== undefined &&
                  zoneList.length > 0 &&
                  zoneList !== null ? (
                    zoneList?.map((row) => {
                      return (
                        <option value={row?.zonename}>
                          {row?.zonename + -row?.zoneno}
                        </option>
                      );
                    })
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table
                responsive="lg"
                hover
                style={{ borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th colSpan="6" className="text-center border-th">
                      Current Year
                    </th>
                  </tr>
                  <tr>
                    {/* <th>Sr No.</th> */}
                    <th>Gat Name</th>
                    <th>Notice Count</th>
                    <th>Meeting Person Count</th>
                    <th>Mobile Verified Count</th>
                    <th>Property Not Found Count</th>
                    <th >Bill Issued Count</th>
                    <th className="border-th">Total Count</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <center>
                      <Spinner />
                    </center>
                  ) : (
                    data &&
                    data.data.length > 0 &&
                    data.data.map((row, index) => {
                      return (
                        <tr key={index}>
                          {/* <td className="border-tr-new">
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td> */}
                          <td className="border-tr-new">
                            {row?.gatname ? row?.gatname : "-"}
                          </td>

                          <td className="border-tr-new">
                            {row?.noticeCount ? row?.noticeCount : 0}
                          </td>

                          <td className="border-tr-new">{row?.meetingPersonCount ? row?.meetingPersonCount : 0 }</td>

                          <td className="border-tr-new">
                            {row?.mobileVerifiedCount
                              ? row?.mobileVerifiedCount
                              : 0}
                          </td>

                          <td className="border-tr-new">
                            {row?.propertyNotFoundCount
                              ? row?.propertyNotFoundCount
                              : 0}
                          </td>

                          <td className="border-tr-new">
                            {row?.propertyFoundCount
                              ? row?.propertyFoundCount
                              : 0}
                          </td>

                          <td className="border-tr">
                            {row?.totalCount
                              ? row?.totalCount
                              : 0}
                          </td>
                          {/* <td>Action here</td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
                <tfoot>
                  {data !== undefined && data.data.length > 0 ? (
                    <tr className="sum-row">
                      <td colSpan="1" className="border-th-new">
                        <strong className="m-2">Total Counts :-</strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          {data &&
                          data.totalNoticeCount !== undefined &&
                          data.totalNoticeCount !== null
                            ? data.totalNoticeCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          {data &&
                          data.totalMeetingPersonCount !== undefined &&
                          data.totalMeetingPersonCount !== null
                            ? data.totalMeetingPersonCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          {data &&
                          data.totalMobileVerifiedCount !== undefined &&
                          data.totalMobileVerifiedCount !== null
                            ? data.totalMobileVerifiedCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          {data &&
                          data.totalPropertyNotFoundCount !== undefined &&
                          data.totalPropertyNotFoundCount !== null
                            ? data.totalPropertyNotFoundCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th">
                        <strong>
                          {data &&
                          data.totalPropertyFoundCount !== undefined &&
                          data.totalPropertyFoundCount !== null
                            ? data.totalPropertyFoundCount
                            : "0"}
                        </strong>
                      </td>
                      {/* <td>
                        <strong>{data.totalPropertyNotFoundCount ? data.totalPropertyNotFoundCount : '0'}</strong>
                      </td>*/}
                      <td>
                        <strong>{data.totalVisits ? data.totalVisits : '0'}</strong>
                      </td> 
                    </tr>
                  ) : null}
                </tfoot>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NewGatWiseBillAnalysis;
