import React from 'react';
import PageNotFoundImg from '../../Assets/404.webp';
import { Button, Col, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogout } from '../../Feature/Login/LoginSlice';
import { setActiveKey } from '../../Feature/Sidebar/ActiveKeySlice';
import { setLabelDate, setLabelMonth } from '../../Feature/Graph/label';


const PageNotFound = () => {

  const dispatch = useDispatch();

  const handleNavigate = async()=>{
    dispatch(setLogout(null));
    dispatch(setActiveKey(0));
    dispatch(setLabelDate(''));
    dispatch(setLabelMonth(''));
    navigate('/');
  }

  const navigate = useNavigate();
  return (
    <div className='PageNotFound'>
      <Row className='justify-content-center' data-aos="fade-up">
        <Col md={10} className='text-center'>
          <img src={PageNotFoundImg} className='img-fluid' alt="Page Not Found"/>
          <h1>Page Not Found</h1>
          <p>* Please, login with valid credentails to enter in the system</p>
          <Button className='btn-submit mx-auto' onClick={()=>handleNavigate('/')}>
              <Icon.BoxArrowInRight size={18} className='me-2'/> Go to Login
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default PageNotFound