import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
// import LineChart from '../Charts/LineChart';
import { useSelector } from 'react-redux';
import BarChart from '../Charts/BarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';


const BillDistribution = () => {

  const userState = useSelector(state => state.loginData.userData);
  
  useEffect(() => {
    if (userState.usertype_keyname[0] === 'admin') {
      getMonths();
    }
  }, [])

  const [months, setMonths] = useState([]);
  const [monthCounts, setMonthCounts] = useState([]);

  const [dates, setDates] = useState([]);
  const [dateCounts, setDateCounts] = useState([]);

  const [hours, setHours] = useState([]);
  const [hourCounts, setHourCounts] = useState([]);


  const labelMonth = useSelector(state => state.label.labelMonth);

  const labelDate = useSelector(state => state.label.labelDate);


  useEffect(() => {
    getDates(labelMonth)
  }, [labelMonth])

  useEffect(() => {
    getHours(labelDate)
  }, [labelDate])


  const [dateLoader, setDateLoader] = useState(false);
  const [hoursLoader, setHoursLoader] = useState(false);

  const currentDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split("T")[0];
  const currentMonth = new Date().getMonth() + 1;


  const getMonths = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/graph/monthwisecount', {
        method: 'GET'
    }).then((res) => res.json()).then((response) => {
      setMonths(response.months);
      setMonthCounts(response.countOfMonth);
      // let CountArray = [];
      // CountArray.push({
      //     label: "Month Counts",
      //     data: response.countOfMonth,
      //     borderColor: "#073c7c",
      //   });
      //   setMonthCounts(CountArray);
    }).catch((err) => {
      console.log("Err while Get Monthwise Chart", err);
    })
  }


  const getDates = async (labelMonth) => {

    await fetch(process.env.REACT_APP_BASE_URL + '/graph/datewisecount', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ month: labelMonth ? labelMonth : currentMonth })
    }).then((res) => res.json()).then((response) => {
      setDates(response.dates);
      setDateCounts(response.countOfDate);
      setDateLoader(false)

    }).catch((err) => {
      console.log("Err while Get HourWise Chart", err);
    })
  }

  const [hoursData, setHoursData] = useState(false)


  const getHours = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/graph/hourwisecount', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ date: labelDate ? labelDate : currentDate })
    }).then((res) => res.json()).then((response) => {
      setHoursData(false)
      setHours(response.hours);
      setHourCounts(response.countOfHours);
      setHoursLoader(false);
    }).catch((err) => {
      console.log("Err while Get HourWise Chart", err);
    })
  }

  const handleDateLoader = () => {
    setDateLoader(true)
  }

  const handleHoursLoader = () => {
    setHoursLoader(true)
  }


  //  const MonthWiseColors=[];
  //  MonthWiseColors.push('rgb(7, 60, 124, 0.6)');

  const DateWiseColors = [];
  DateWiseColors.push('rgba(255, 136, 0, 0.6)');


  return (
    <div className='BuildDistribution'>
      <Container>
        
        {
          userState.usertype_keyname[0] === "admin" ?
            <>
              <Row className='justify-content-center'>
                <Col md={4}>
                  <Card className="ChartCard" style={{ height: "250px", maxHeight: "250px", padding: '12px 0px 12px 0px' }}>
                    <h6 className='mb-0'>Month Wise Distribution</h6>
                    <BarChart chartLabels={months} chartLabel="Month Counts" ColorArray='rgb(18, 103, 207, 0.4)' BorderArray='#073c7c'
                      chartData={monthCounts} chartHeading="Month Wise Distribution" handleDateLoader={() => { handleDateLoader() }} dateParam="date" />
                  </Card>
                </Col>
              </Row>

              <Row className='justify-content-center mt-4 gx-5'>
                <Col md={12}>
                  <Card className="ChartCard" style={{ height: "280px", maxHeight: "280px", padding: '22px 0px 20px 0px' }}>
                    <h6 className='mb-0'>Date Wise Distribution</h6>
                    <div><p style={{ background: 'linear-gradient(315deg, #ff3c00 0%, #ff8800 100%)' }}>Month-{labelMonth ? labelMonth : currentMonth}</p></div>
                    {
                      dateLoader ?
                        <center>
                          <img src={GraphLoader} width="150" maxHeight="250px" />
                        </center>

                        : <BarChart chartLabels={dates} chartLabel="Date Counts" ColorArray='rgba(255, 60, 0, 0.3)' BorderArray='#ff3c00'
                          chartData={dateCounts} chartHeading="Date Wise Distribution" handleDateLoader={() => { handleHoursLoader() }} dateParam="hours" />
                    }
                  </Card>

                  {/* <LineChart chartHeading /> */}
                </Col>

                <Col md={5} className='my-4'>
                  <Card className="ChartCard" style={{ height: "280px", maxHeight: "280px", padding: '25px 0px 20px 0px' }}>
                    <h6>Hour Wise Distribution</h6>
                    <div><p style={{ background: 'linear-gradient(315deg, #00b712, #40d400 100%)' }}>Date-{labelDate ? labelDate : currentDate}</p></div>
                    {
                      hoursLoader ?
                        <center>
                          <img src={GraphLoader} width="150" maxHeight="250px" />
                        </center>
                        :
                        hoursData ?
                          "Data not found. please select another month or data"
                          :
                          <BarChart chartLabels={hours} chartLabel="Hour Counts" ColorArray='rgba(0, 183, 18, 0.3)' BorderArray='#00B712'
                            chartData={hourCounts} chartHeading="Hour Wise Distribution" />
                    }
                  </Card>
                  {/* <LineChart months={hours} Array={hourCounts} chartHeading="HourWise Distribution" /> */}
                </Col>
              </Row>

              <Row>

              </Row>
            </>
            : ""}
      </Container>
    </div>
  )
}

export default BillDistribution;