import React from 'react';

const UserCardSkeleton = () => {
  return (
    <div>
    <div className='UserCardSkeleton'>

        <div className='leftLabel'></div>
        <div className='rightLabel'></div>

        <div className='row mt-2'>
            <div className='data'></div>
        </div>
        <div className='row'>
            <div className='data'></div>
        </div>
        <div className='row'>
            <div className='data'></div>
        </div>
        <div className='row'>
           <div className='data'></div>
        </div>
        <div className='row'>
                <div className='btn'></div>
        </div>
    </div>

    <div className='UserCardSkeleton'>

        <div className='leftLabel'></div>
        <div className='rightLabel'></div>

        <div className='row mt-2'>
            <div className='data'></div>
        </div>
        <div className='row'>
            <div className='data'></div>
        </div>
        <div className='row'>
            <div className='data'></div>
        </div>
        <div className='row'>
           <div className='data'></div>
        </div>
        <div className='row'>
                <div className='btn'></div>
        </div>
    </div>
    </div>
  )
}

export default UserCardSkeleton