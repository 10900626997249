import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Tab,
  Nav,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";

const NewContractor = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const navigate = useNavigate();

  // Variables for Zone-Gat Visitors Data ------------
  const [contractor, setContractor] = useState();
  const [lessThanTen, setLessThanTen] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [zoneB, setZoneB] = useState("");
  const [zoneErr, setZoneErr] = useState(false);
  const [loader, setLoader] = useState(false);

  // Search by Mobile----
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobErr, setSearchMobErr] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  const [key, setKey] = useState("allData");

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getZoneList();
    getLessThanTenPercentageList();
  }, []);

  //   useEffect API Calls
  useEffect(() => {
    getContarctorList();
  }, [docPerPage, pageNo]);

  // get List API
  const getContarctorList = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/billDistribution/contractorReport",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          visitingPersonContactNo: searchMobile,
          zonekey: zoneB,
          fromDate: startDate,
          toDate: endDate,
          documentsPerPage: docPerPage,
          page: pageNo,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractor(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const getLessThanTenPercentageList = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/billDistribution/toggleContractorReport",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          visitingPersonContactNo: searchMobile,
          zonekey: zoneB,
          fromDate: startDate,
          toDate: endDate,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setLessThanTen(response);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    } else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  };

  const searchByDate = () => {
    if (searchByStartEndValidate()) {
      key === "lessThanTen"
        ? getLessThanTenPercentageList()
        : getContarctorList();
    }
  };

  const searchByMobile = () => {
    if (searchMobile.length === 10) {
      key === "lessThanTen"
        ? getLessThanTenPercentageList()
        : getContarctorList();
    } else {
      toast.error("Please Enter Valid Mobile Number.");
    }
  };

  const searchByZone = () => {
    if (zoneB.length > 0) {
      key === "lessThanTen"
        ? getLessThanTenPercentageList()
        : getContarctorList();
    } else {
      toast.error("Please Select Zone first.");
    }
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="searchCard m-2">
            <div className="label mb-2 mx-auto">
              <p>Search by mobile</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Control
                className="me-3"
                placeholder="Enter mobile number"
                value={searchMobile}
                name="contactNo"
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    setSearchMobile(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setSearchMobErr(false)
                    : setSearchMobErr(true);
                }}
              />

              <Button className="btn-submit" onClick={searchByMobile}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {searchMobErr ? <p>Please enter valid mobile number</p> : null}
          </Col>
          <Col md={4} className="searchCard m-2">
            <div className="label mb-2 mx-auto">
              <p>Search by Zone</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Select
                className="me-2"
                value={zoneB}
                onChange={(e) => setZoneB(e.target.value)}
              >
                <option value="" selected disabled>
                  {" "}
                  Select Zone
                </option>
                {zoneList !== undefined && zoneList.length > 0 ? (
                  zoneList.map((row) => {
                    return (
                      <option value={row.zonekey}>
                        {row.zonename + "-" + row.zoneno}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>
              <Button className="btn-submit" onClick={searchByZone}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {zoneErr ? <p>Please select any zone</p> : null}
          </Col>
          <Col md={6} className="searchCard justify-content-center mb-4">
            <div className="label mb-2 mx-auto">
              <p>Search by Start Date/End Date</p>
            </div>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setEndDate("");
                    e.target.value.length > 0 && setStartDateErr(false);
                  }}
                />
                {startDateErr ? (
                  <p className="errMsg">Please select Start Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  min={startDate ? startDate : ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                    e.target.value.length > 0 && setEndDateErr(false);
                  }}
                />
                {endDateErr ? (
                  <p className="errMsg">Please select End Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={2} className="mt-4">
                <Button className="btn btn-submit" onClick={searchByDate}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col className="tab">
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className="flex-column align-items-center">
                <Col xs={12} className="d-flex justify-content-end p-0">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="allData">All Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="lessThanTen">Less Than 10%</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col className="mt-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="lessThanTen">
                      <div className="container tbl-container p-0 mb-5">
                        <div
                          className="row tbl-fixed p-0"
                          style={{ maxHeight: "65vh" }}
                        >
                          <Table responsive="lg" hover>
                            <thead>
                              <th>Sr No.</th>
                              <th>Name</th>
                              <th>Mobile</th>
                              <th>Notice Count</th>
                              {/* <th>Meeting Person Count</th> */}
                              <th>Mobile Verified Count</th>
                              <th>Property Found Count</th>
                              <th>Property Not Found Count</th>
                              <th>Total Count</th>
                              <th>Mobile Updated (%)</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              {loader ? (
                                <center>
                                  <Spinner />
                                </center>
                              ) : (
                                lessThanTen &&
                                lessThanTen.data.length > 0 &&
                                lessThanTen.data.map((row, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className={!row.isValid ? "red-row" : ""}
                                    >
                                      <td>
                                        {pageNo !== 1 ? (
                                          <>
                                            {" "}
                                            {index +
                                              1 +
                                              docPerPage * (pageNo - 1)}
                                          </>
                                        ) : (
                                          <>{index + 1}</>
                                        )}
                                      </td>
                                      <td>{row?.name ? row?.name : "-"}</td>
                                      <td>{row?.mobile ? row?.mobile : "-"}</td>
                                      <td>
                                        {row?.noticeCount
                                          ? row?.noticeCount
                                          : 0}
                                      </td>
                                      {/* <td>
                            {row?.meetingPersonCount
                              ? row?.meetingPersonCount
                              : 0}
                          </td> */}
                                      <td>
                                        {row?.mobileVerifiedCount
                                          ? row?.mobileVerifiedCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.propertyFoundCount
                                          ? row?.propertyFoundCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.propertyNotFoundCount
                                          ? row?.propertyNotFoundCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.totalCount ? row?.totalCount : 0}
                                      </td>
                                      <td>
                                        {row?.percentage ? row?.percentage : 0}
                                      </td>
                                      <td className="text-center bg-white text-black">
                                        <Icon.Eye
                                          style={{ cursor: "pointer" }}
                                          size={25}
                                          onClick={() =>
                                            navigate(
                                              "/dashboard/view-contractor-report",
                                              {
                                                state: {
                                                  contractor: row,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                            <tfoot>
                              {lessThanTen !== undefined &&
                              lessThanTen.data.length > 0 ? (
                                <tr className="sum-row">
                                  <td colSpan="3">
                                    <strong className="m-2">
                                      Total of the Counts :-
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {lessThanTen.totalNoticeCount
                                        ? lessThanTen.totalNoticeCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  {/* <td>
                        <strong>
                          {lessThanTen.totalMeetingPersonCount
                            ? lessThanTen.totalMeetingPersonCount
                            : "0"}
                        </strong>
                      </td> */}
                                  <td>
                                    <strong>
                                      {lessThanTen.totalMobileVerifiedCount
                                        ? lessThanTen.totalMobileVerifiedCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {lessThanTen.totalPropertyFoundCount
                                        ? lessThanTen.totalPropertyFoundCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {lessThanTen.totalPropertyNotFoundCount
                                        ? lessThanTen.totalPropertyNotFoundCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {lessThanTen.totalVisits
                                        ? lessThanTen.totalVisits
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ) : null}
                            </tfoot>
                          </Table>
                        </div>
                        <Row>
                          <Col
                            md={4}
                            className="d-flex align-items-center mt-3"
                          >
                            <h6 className="text-start mb-0">
                              <Icon.People className="me-2" />
                              Total - <strong>{lessThanTen?.count}</strong>
                            </h6>
                          </Col>
                          {/* <Col md={8} className="d-flex justify-content-end">
                            <Pagination
                              currentPage={pageNo}
                              totalPages={noOfPages}
                              onPageChange={handlePageChange}
                              onDocsPerPage={handleDocsPerPage}
                              docsPerPage={docPerPage}
                            />
                          </Col> */}
                        </Row>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="allData">
                      <h6>
                        Note :- Highlighted row represents less than 10% Mobile
                        Updated.
                      </h6>
                      <div className="container tbl-container p-0">
                        <div
                          className="row tbl-fixed p-0"
                          style={{ maxHeight: "65vh" }}
                        >
                          <Table responsive="lg" hover>
                            <thead>
                              <th>Sr No.</th>
                              <th>Name</th>
                              <th>Mobile</th>
                              <th>Notice Count</th>
                              {/* <th>Meeting Person Count</th> */}
                              <th>Mobile Verified Count</th>
                              <th>Property Found Count</th>
                              <th>Property Not Found Count</th>
                              <th>Total Count</th>
                              <th>Mobile Updated (%)</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              {loader ? (
                                <center>
                                  <Spinner />
                                </center>
                              ) : (
                                contractor &&
                                contractor.data.length > 0 &&
                                contractor.data.map((row, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className={!row.isValid ? "red-row" : ""}
                                    >
                                      <td>
                                        {pageNo !== 1 ? (
                                          <>
                                            {" "}
                                            {index +
                                              1 +
                                              docPerPage * (pageNo - 1)}
                                          </>
                                        ) : (
                                          <>{index + 1}</>
                                        )}
                                      </td>
                                      <td>{row?.name ? row?.name : "-"}</td>
                                      <td>{row?.mobile ? row?.mobile : "-"}</td>
                                      <td>
                                        {row?.noticeCount
                                          ? row?.noticeCount
                                          : 0}
                                      </td>
                                      {/* <td>
                            {row?.meetingPersonCount
                              ? row?.meetingPersonCount
                              : 0}
                          </td> */}
                                      <td>
                                        {row?.mobileVerifiedCount
                                          ? row?.mobileVerifiedCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.propertyFoundCount
                                          ? row?.propertyFoundCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.propertyNotFoundCount
                                          ? row?.propertyNotFoundCount
                                          : 0}
                                      </td>
                                      <td>
                                        {row?.totalCount ? row?.totalCount : 0}
                                      </td>
                                      <td>
                                        {row?.percentage ? row?.percentage : 0}
                                      </td>
                                      <td className="text-center bg-white text-black">
                                        <Icon.Eye
                                          style={{ cursor: "pointer" }}
                                          size={25}
                                          onClick={() =>
                                            navigate(
                                              "/dashboard/view-contractor-report",
                                              {
                                                state: {
                                                  contractor: row,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                            <tfoot>
                              {contractor !== undefined &&
                              contractor.data.length > 0 ? (
                                <tr className="sum-row">
                                  <td colSpan="3">
                                    <strong className="m-2">
                                      Total of the Counts :-
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {contractor.totalNoticeCount
                                        ? contractor.totalNoticeCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  {/* <td>
                        <strong>
                          {contractor.totalMeetingPersonCount
                            ? contractor.totalMeetingPersonCount
                            : "0"}
                        </strong>
                      </td> */}
                                  <td>
                                    <strong>
                                      {contractor.totalMobileVerifiedCount
                                        ? contractor.totalMobileVerifiedCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {contractor.totalPropertyFoundCount
                                        ? contractor.totalPropertyFoundCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {contractor.totalPropertyNotFoundCount
                                        ? contractor.totalPropertyNotFoundCount
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {contractor.totalVisits
                                        ? contractor.totalVisits
                                        : "0"}
                                    </strong>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ) : null}
                            </tfoot>
                          </Table>
                        </div>
                        <Row>
                          <Col
                            md={4}
                            className="d-flex align-items-center mt-3 mt-md-0"
                          >
                            <h6 className="text-start mb-0">
                              <Icon.People className="me-2" />
                              Total - <strong>{contractor?.count}</strong>
                            </h6>
                          </Col>
                          <Col md={8} className="d-flex justify-content-end">
                            <Pagination
                              currentPage={pageNo}
                              totalPages={noOfPages}
                              onPageChange={handlePageChange}
                              onDocsPerPage={handleDocsPerPage}
                              docsPerPage={docPerPage}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewContractor;
