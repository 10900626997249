import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ZoJaptiNotice = () => {

  const userState = useSelector(state => state.loginData.userData);

  const [gat, setGat] = useState('');
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [noticeDetails, setNoticeDetails] = useState();
  const [japtiDate, setJaptiDate] = useState();

  // Variables for Zone & Gat LIST ------------
  const [allGatList, setAllGatList] = useState([]);
  const [japtiPersonList, setJaptiPersonList] = useState([]);
  const [japtiPersonMobile, setJaptiPersonMobile] = useState('');
  const [propertySearchId, setPropertySearchId] = useState('');
  const [isJaptiNotice, setIsJaptiNotice] = useState();

  //Varaibles for Filtering Gat depending on Zone ----------
  const [filterGat, setFilterGat] = useState([]);

  useEffect(() => {
    getGatList();
    getJaptiData();
    getJaptiNoticePersonList();
  }, [])

  useEffect(() => {
    getFiltertedGat();
    setGat("");
  }, [userState?.zonekey]);

  const getFiltertedGat = () => {
    const result = allGatList?.filter((row) => row.zonekey == userState?.zonekey);
    setFilterGat(result);
  };

  // Function for Fetching GAT LIST --------------------
  const getGatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ zonekey: userState?.zonekey })
    })
      .then((res) => res.json())
      .then((response) => {
        setAllGatList(response.data);
        setFilterGat(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching JAPTI NOTICE PERSON LIST --------------------
  const getJaptiNoticePersonList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/japtinotice/getJaptiNoticePerson`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setJaptiPersonList(response);
      })
      .catch((err) => {
        console.log("Error While Notice Persons List", err);
      });
  };

  // function for getall japtinotice data
  const getJaptiData = async () => {
    setLoader(true);
    const payload = {
      zonekey: userState?.zonekey,
      gatkey: gat ? gat : "",
      japtiNoticeDate: japtiDate ? japtiDate : "",
      japtiPersonMobile: japtiPersonMobile ? japtiPersonMobile : "",
      propertySearchId: propertySearchId ? propertySearchId : "",
      isJaptiNotice: isJaptiNotice ? isJaptiNotice : ""
    }
    await fetch(process.env.REACT_APP_BASE_URL + '/zonalOfficer/getAllJaptiNotice', {

      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then((res) => res.json()).then((response) => {
      setData(response);
      setLoader(false);
    }).catch((err) => {
      console.log("Err while getting Japti Data", err);
      setLoader(false);
    })
  }

  return (
    <div className='JaptiKarvai'>
      <Container>

        <div className='d-flex justify-content-center align-items-center mb-4'>
          <Icon.CardHeading className='me-2' size={22} color='#073c7c' />
          <h5 className='p-0 text-center mb-0'>Japti Notice</h5>
        </div>

        <Row className="justify-content-center mb-4">
          <Col md={5} className="searchCard justify-content-center">
            <div className="label mb-2 mx-auto"><p>Search by Zone/Gat</p></div>
            <Row className="parentRow d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Control as="select" disabled>
                  {userState.zonekey && (
                    <option key={userState.zonekey} value={userState.zonekey}>
                      {userState.zonename + "-" + userState.zoneno}
                    </option>
                  )}
                </Form.Control>
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={gat} onChange={(e) => setGat(e.target.value)}>
                  <option value="" selected disabled>
                    Select Gat
                  </option>
                  {filterGat !== undefined && filterGat.length > 0 ? (
                    filterGat.map((row) => {
                      return (
                        <option value={row?.gatkey}>{row.mar_gatname + '-' + row.gatname}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>

          </Col>

          <Col md={4} className="searchCard justify-content-center ms-0 ms-md-4">
            <div className="label mb-2 mx-auto"><p>Search by Japti Person</p></div>
            <Row>
              <Col md={8}>

                <Form.Select value={japtiPersonMobile} onChange={(e) => setJaptiPersonMobile(e.target.value)}>
                  <option value="" selected disabled>
                    Select any japti person
                  </option>
                  {japtiPersonList?.japtiPersons !== undefined && japtiPersonList.japtiPersons?.length > 0 ? (
                    japtiPersonList.japtiPersons?.map((row) => {
                      return (
                        <option value={row?.japtiPersonMobile}>{row.japtiPersonName}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

        <Row className='justify-content-center'>
          <Col md={4} className="searchCard justify-content-center mb-4">
            <div className="label mb-2 mx-auto"><p>Search by Property Search Person</p></div>
            <Row>
              <Col md={8}>

                <Form.Select value={propertySearchId} onChange={(e) => setPropertySearchId(e.target.value)}>
                  <option value="" selected disabled>
                    Select Property Search Person
                  </option>
                  {japtiPersonList?.propertySearchPersons !== undefined && japtiPersonList.propertySearchPersons?.length > 0 ? (
                    japtiPersonList.propertySearchPersons?.map((row) => {
                      return (
                        <option value={row?.propertySearchId}>{row?.propertySearchName + '-' + row?.propertySearchMobile}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>


            </Row>
          </Col>

          <Col md={4} className="searchCard justify-content-center mb-4 ms-0 ms-md-4">
            <div className="label mb-2 mx-auto"><p>Search by Notice Status</p></div>
            <Row>
              <Col md={8}>

                <Form.Select value={isJaptiNotice} onChange={(e) => setIsJaptiNotice(e.target.value)}>
                  <option value="" selected disabled>
                    Select Property Search Person
                  </option>
                  <option value={true}>Japti notice given</option>
                  <option value={false}>Japti property searched</option>
                </Form.Select>

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>


            </Row>
          </Col>

          <Col md={4} className="searchCard justify-content-center mb-4">
            <div className="label mb-2 mx-auto"><p>Search by Date</p></div>
            <Row>
              <Col md={8}>
                <Form.Control type="date" value={japtiDate} max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => setJaptiDate(moment(e.target.value).format("YYYY-MM-DD"))}
                />

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>


            </Row>
          </Col>

        </Row>

        <h5 className='text-center'>Total count - {data?.japtiNoticeCount}</h5>
        <Row>
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '65vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Property Code</th>
                    <th>Property Name</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Visit Date</th>
                    <th>Notice Date</th>
                    <th>Property Search Person</th>
                    <th>Japti Person</th>
                    <th>Created By</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {data?.data !== null && data?.data !== undefined && data?.data.length > 0
                      ? data?.data.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center", cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td>{row?.propertyCode}</td>
                            <td style={{}}>{row?.propertyName}</td>
                            <td>{row?.zonename}</td>
                            <td>{row?.gatname.toString()}</td>
                            <td>{row?.visitDate ? moment(row?.visitDate).format("DD-MM-YYYY") : '-'}</td>
                            <td>{row?.japtiNoticeDate ? moment(row?.japtiNoticeDate).format("DD-MM-YYYY") : '-'}</td>
                            <td>{row?.propertySearchName ? row?.propertySearchName + `${row?.propertySearchMobile ? `(` + row?.propertySearchMobile + `)` : ''}` : '-'}</td>
                            <td >
                              {row?.japtiPersonName ? row?.japtiPersonName + ` ${row?.japtiPersonMobile ? `(` + row?.japtiPersonMobile + `)` : ''}` : '-'}</td>
                            <td>
                              {row?.createdByName ? row?.createdByName + `${row?.createdByMobile ? `(` + row?.createdByMobile + `)` : ''}` : '-'}</td>
                            <td><Icon.Eye size={20} onClick={() => { setNoticeDetails(row); setShowNotice(true); }} /></td>
                          </tr>
                        );
                      })
                      : "No users data found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>

        </Row>


        {/* --------- Japti Notice Details View Modal --------- */}

        <Modal dialogClassName="modal" show={showNotice} onHide={() => setShowNotice(false)} centered backdrop="static" keyboard={false} size="lg" >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <Icon.Coin className='me-2' />
              <h5 className="mb-0">View Japti Notice Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className='data'>
                  <h6>{noticeDetails?.isJaptiNotice ? <Icon.ToggleOn className='me-2' size={16} /> :
                    <Icon.ToggleOff className='me-2' size={16} />}Notice Status : </h6>
                  <p>{noticeDetails?.isJaptiNotice ? <Icon.CheckCircleFill color='green' /> :
                    <Icon.XCircleFill color='red' />}</p>
                </div>

                <div className='data'>
                  <h6><Icon.ClockFill className='me-2' />Japti Date : </h6>
                  <p>{noticeDetails?.japtiDate ?
                    moment(noticeDetails?.japtiDate).format("YYYY-MM-DD") : '-'}</p>
                </div>

                <div className='data'>
                  <h6><Icon.ClockFill className='me-2' />Japti Notice Date : </h6>
                  <p>{noticeDetails?.japtiNoticeDate ?
                    moment(noticeDetails?.japtiNoticeDate).format("YYYY-MM-DD") : '-'}</p>
                </div>

                <div md={2} className='data'>
                  <h6><Icon.PersonFill className='me-2' />Japti Given By : </h6>
                  <p>{noticeDetails?.japtiPersonName ? noticeDetails?.japtiPersonName : '-' + ` ${noticeDetails?.japtiPersonMobile ? `(` + noticeDetails?.japtiPersonMobile + `)` : ''}`}</p>
                </div>

                <div md={2} className='data'>
                  <h6><Icon.PersonFill className='me-2' />Visited By : </h6>
                  <p>{noticeDetails?.createdByName ? noticeDetails?.createdByName : '-' + ` ${noticeDetails?.createdByMobile ? `(` + noticeDetails?.createdByMobile + `)` : ''}`}</p>
                </div>

              </Col>

              <Col md={6}>
                <div className='data'>
                  <h6><Icon.BuildingFill className='me-2' />Property Code :</h6>
                  <p>{noticeDetails?.propertyCode}</p>
                </div>

                <div className='data'>
                  <h6><Icon.PersonFill className='me-2' />Property Name :</h6>
                  <p>{noticeDetails?.propertyName}</p>
                </div>

                <div className='data'>
                  <h6><Icon.PersonFill className='me-2' />Meeting Person :</h6>
                  <p>{noticeDetails?.meetingPersonName ? noticeDetails?.meetingPersonName + `(${noticeDetails?.meetingPersonContactNo})` : '-'}</p>
                </div>

                <div md={3} className='data'>
                  <h6><Icon.GeoFill className='me-2' />Zone :</h6>
                  <p>{noticeDetails?.zonename}</p>
                </div>

                <div md={3} className='data'>
                  <h6><Icon.GeoFill className='me-2' />Gat :</h6>
                  <p>{noticeDetails?.gatname}</p>
                </div>
              </Col>

            </Row>

            <Row>

              <div className='data'>
                <h6><Icon.BuildingFill className='me-2' />Udyog Karta Shulk :</h6>
                <p>{noticeDetails?.upayogakartaShulkName ? noticeDetails?.upayogakartaShulkName : '-'}</p>
              </div>

              <div className='data'>
                <h6><Icon.GeoAltFill className='me-2' />Address :</h6>
                <p>{noticeDetails?.propertyAddress}</p>
              </div>

              <div className='data'>
                <h6><Icon.GeoAltFill className='me-2' />Updated Address :</h6>
                <p>{noticeDetails?.addressUpdated ? noticeDetails?.addressUpdated : '-'}</p>
              </div>

              <Row className='d-flex'>
                {
                  noticeDetails?.propertyImg.length > 0 ?
                    <Col md={3}>
                      <h6><Icon.ImageFill className='me-2' color='#073c7c' />Property Image</h6>
                      <div className='ms-5'>
                        <a href={noticeDetails?.propertyImg} target='_blank'><Icon.ImageFill size={30} /></a>
                        {/* <img src={noticeDetails?.propertyImg} alt="Property" width={200} height={200}/> */}
                      </div>
                    </Col> : ''
                }

                {
                  noticeDetails?.japtiNoticeDocumentImage.length > 0 ?
                    <Col md={3}>
                      <h6><Icon.ImageFill className='me-2' color='#073c7c' />Notice Document</h6>
                      <div className='ms-5'>
                        <a href={noticeDetails?.japtiNoticeDocumentImage} target='_blank'><Icon.ImageFill size={30} /></a>
                      </div>
                    </Col> : ''
                }

                {
                  noticeDetails?.japtiNoticeImage.length > 0 ?
                    <Col md={3}>
                      <h6><Icon.ImageFill className='me-2' color='#073c7c' />Notice Image</h6>
                      <div className='ms-5'>
                        <a href={noticeDetails?.japtiNoticeImage} target='_blank'><Icon.ImageFill size={30} /></a>
                      </div>
                    </Col> : ''

                }

              </Row>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  )
}

export default ZoJaptiNotice;